import { Logging } from "./logging";
import Storage from "./storage";
import { LevelSavedState } from "../level-state";
import LevelManager, { LoadingTypes, SaveType } from "../levels";
import WorkspaceManager from "../workspace";
import { translate } from "../i18n/i18n";
import { sa_event } from "./analytics";
import RenderUtils from '../render_utils';
import { endLoading } from '../containers/LoadingOverlay';

const rdiff = require('recursive-diff');

export enum RestoreCostumeAddStatus {
    NONE,
    COSTUME,
    SPRITE,
    RESET
}

interface RestorePoint {
    time: number,
    comment: string,
    changes: object
}

interface RestoreManager {
    backgroundImage: string,
    restorePoints: RestorePoint[],
    lastRestoreSave: LevelSavedState,
    lastDownloadedLevel: LevelSavedState,
    lastDownloadedLevelTime: number,
    version: string
}

export abstract class Restore {
    private static initialized = false;
    private static autoSaveInterval = 2 * 60000; //2 minutes;
    private static maxRestorePoints = 3;
    private static _costumeAddStatus: RestoreCostumeAddStatus = RestoreCostumeAddStatus.NONE;
    public static blockedReset = false;
    public static blockedCreate = false;
    private static identifier = 'RestoreManager';
    private static version = '1.0.2';

    public static initialize() {
        if (!this.initialized) {
            this.initialized = true;
            if (Storage.get(this.identifier) === undefined) {
                this.generateStorage();
            } else {
                if (Storage.get(this.identifier).version !== this.version) {
                    Storage.del(this.identifier);
                    this.generateStorage();
                } else {
                    this.blockedReset = true;
                }
            }
            setInterval(() => this.createRestorePoint(translate('RestoreMessages.automatic')), this.autoSaveInterval);
        }
    }

    public static reset(level: LevelSavedState) {
        if (!this.blockedReset) {
            let restoreManager = Storage.get(this.identifier) as RestoreManager;
            restoreManager.backgroundImage = level.projectdata.background.costumes[0].data;
            restoreManager.lastRestoreSave = level;
            restoreManager.restorePoints = [];
            restoreManager.lastDownloadedLevel = level;
            restoreManager.lastDownloadedLevelTime = this.getTimeStamp();
            Storage.set(this.identifier, restoreManager);
        } else {
            this.blockedReset = false;
        }
    }

    public static createRestorePoint(comment: string) {
        if (!this.blockedCreate) {
            let restoreManager = Storage.get(this.identifier) as RestoreManager;
            Logging.info('Create restore point: ' + comment);
            let levelManager = (window as any).levelManager as LevelManager;
            if (restoreManager.restorePoints.length === this.maxRestorePoints) {
                restoreManager.restorePoints.shift();
            }
            let level = levelManager.save(SaveType.OBJECT);

            let newRestorePoint = {
                time: this.getTimeStamp(),
                comment: comment,
                changes: rdiff.getDiff(level, restoreManager.lastRestoreSave)
            };
            restoreManager.restorePoints.push(newRestorePoint);
            restoreManager.lastRestoreSave = level;
            if (!Storage.set(this.identifier, restoreManager)) {
                this.dropRestorePointsUntilFreeOrEmpty(restoreManager);
            }
        }
    }

    public static canRestore() {
        let restoreManager = Storage.get(this.identifier) as RestoreManager;
        return restoreManager.restorePoints?.length > 0;
    }

    public static loadMostRecentRestorePoint() {
        let restoreManager = Storage.get(this.identifier) as RestoreManager;
        Logging.info('Loading most recent restore point: ' + restoreManager.restorePoints[0].comment);
        let levelManager = (window as any).levelManager as LevelManager;
        let workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        const loadInfo = {
            type: LoadingTypes.LOCAL_JSON,
            buildMode: LevelManager.isBuildMode()
        };
        let level = restoreManager.lastRestoreSave;
        workspaceManager.stopAll();
        levelManager.load(level, loadInfo, undefined, () => {
            //Despite using the level finsihed callback, we need to wait some more time.
            //This is a BUG. I assume that Blockly (or maybe even our code) is doing some background work async so that
            //no blocks are present when the callback is called.
            //Using setTimeout(..., 0) sets the this.runProject() to the current bottom of the javascript stack, so that we are queued after all other async functions.)
            setTimeout(() => {
                ((window as any).renderUtils as RenderUtils).generateColorPalette();
                (window as any).app_onResizeSplitPanel();
                endLoading();
            }, 300);
        });
        restoreManager.lastRestoreSave = level;
        Storage.set(this.identifier, restoreManager);
    }

    public static loadRestorePoint(id: number) {
        sa_event('load_restore_point');

        let restoreManager = Storage.get(this.identifier) as RestoreManager;
        Logging.info('Load restore point: ' + restoreManager.restorePoints[id].comment);
        let levelManager = (window as any).levelManager as LevelManager;
        let workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        const loadInfo = {
            type: LoadingTypes.LOCAL_JSON,
            buildMode: LevelManager.isBuildMode()
        };

        if (id >= 0 && id < restoreManager.restorePoints.length) {
            let level = restoreManager.lastRestoreSave;
            for (let i = restoreManager.restorePoints.length - 1; i >= id; i--) {
                level = rdiff.applyDiff(level, restoreManager.restorePoints[i].changes);
            }
            workspaceManager.stopAll();
            levelManager.load(level, loadInfo);
            restoreManager.lastRestoreSave = level;
        }
        restoreManager.restorePoints.length = id;
        Storage.set(this.identifier, restoreManager);
    }

    public static setLastDownloadedLevel(level: LevelSavedState) {
        let restoreManager = Storage.get(this.identifier) as RestoreManager;
        restoreManager.lastDownloadedLevel = level;
        restoreManager.lastDownloadedLevelTime = this.getTimeStamp();
        Storage.set(this.identifier, restoreManager);
    }

    public static loadLastDownloadedLevel() {
        let restoreManager = Storage.get(this.identifier) as RestoreManager;
        Logging.info('Load last downloaded file.');
        let levelManager = (window as any).levelManager as LevelManager;
        let workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        const loadInfo = {
            type: LoadingTypes.LOCAL_JSON,
            buildMode: LevelManager.isBuildMode()
        };
        workspaceManager.stopAll();
        levelManager.load(restoreManager.lastDownloadedLevel, loadInfo);
        this.reset(restoreManager.lastDownloadedLevel);
        Storage.set(this.identifier, restoreManager);
    }

    static get costumeAddStatus(): RestoreCostumeAddStatus {
        return this._costumeAddStatus;
    }

    static set costumeAddStatus(status) {
        this._costumeAddStatus = status;
    }

    public static getRestorePoints(): RestorePoint[] {
        let restoreManager = Storage.get(this.identifier) as RestoreManager;
        if (restoreManager) {
            return restoreManager.restorePoints;
        } else {
            return [];
        }
    }

    public static getLastDownloadedLevelTime(): number {
        let restoreManager = Storage.get(this.identifier) as RestoreManager;
        if (restoreManager) {
            return restoreManager.lastDownloadedLevelTime;
        } else {
            return 0;
        }
    }

    public static shrinkRestorePoints() {
        // Removes all but the last 5 restore points
        let restoreManager = Storage.get(this.identifier) as RestoreManager;
        restoreManager.restorePoints.splice(0, restoreManager.restorePoints.length - 5);
        Storage.set(this.identifier, restoreManager);
    }

    public static dropRestorePointsUntilFreeOrEmpty(restoreManager: RestoreManager) {
        restoreManager.restorePoints.shift();
        if (!Storage.set(this.identifier, restoreManager) && restoreManager.restorePoints.length > 0) {
            this.dropRestorePointsUntilFreeOrEmpty(restoreManager);
        }
    }

    /*
        private static removeBackgroundFromLevel(level: LevelSavedState) {
            if (level.projectdata.background) {
                level.projectdata.background.costumes[0].data = '';
            }
            return level;
        }

        private static addBackgroundToLevel(level: LevelSavedState) {
            let restoreManager = Storage.get(this.identifier) as RestoreManager;
            if (level) {
                level.projectdata.background.costumes[0].data = restoreManager.backgroundImage;
            }
            return level;
        }
    */
    private static generateStorage() {
        Storage.set(this.identifier, {
            backgroundImage: null,
            restorePoints: [],
            lastRestoreSave: null,
            lastDownloadedLevel: null,
            lastDownloadedLevelTime: '',
            version: this.version
        });
    }

    private static getTimeStamp() {
        return Date.now();
    }
}