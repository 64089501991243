import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import IT4KidsButtonWithStyles from "./components/Button";

import Files from "../utils/react-files";
import { ReactFilesFile } from "react-files";

import LevelManager, { LoadingTypes } from '../levels';
import Grid from '@material-ui/core/Grid/Grid';
import { AppRoot } from '../app';
import { WithTranslation, withTranslation } from 'react-i18next';

import mt from 'mousetrap';
import Swal from "sweetalert2";
import { Logging } from "../utils/logging";
import { SlideUp } from "./transitions";
import LevelLoadModalTabs from "./components/LevelLoadModalTabs";
import i18n from "i18next";
import { SessionStorage } from "src/utils/storage";
import { LinearProgress } from "@material-ui/core";
import { loadLevelAws } from "../utils/awsCloud";

/**
 * Props for the LevelLoadModal.
 */
interface LevelLoadProps extends WithTranslation {
    readonly trigger: any;
    readonly parent: any;
}

export interface LevelObject {
    readonly title: Record<string, string> | string;
    readonly rating: number;
    readonly description: Record<string, string> | string;
    readonly filename: string;
    readonly solution_exists: boolean;
    readonly template_exists: boolean;
    readonly tutorial_exists?: boolean;
    readonly isModule?: boolean;
    readonly isProject?: boolean;
    readonly isDeveloper?: boolean;
}

/**
 * State of the LevelLoadModal.
 */
interface LevelLoadState {
    open: boolean;
    levelsConfig: Array<LevelObject>;
    customURL: string;
    levelAccessKey: string;
    showProgress: boolean;
}


export const BASE_LEVEL_URL = "https://levels.it-for-kids.org/";

/**
 * Shows a modal allowing to load a local level or one of the default levels.
 */
export class LevelLoadModal extends React.Component<LevelLoadProps, LevelLoadState> {
    constructor(props: LevelLoadProps) {
        super(props);
        this.state = { open: false, levelsConfig: [], customURL: "", levelAccessKey: "", showProgress: false };
    }

    /**
     * Shortcut
     */
    componentDidMount(): void {
        this.loadLevelsConfig();
        mt.bind('ctrl+o', () => {
            if (!AppRoot.INSTANCE.state.isPlaying) {
                this.open();
            }
            return false;
        });
    }

    /**
     * Show this modal.
     */
    open = () => {
        this.setState({ open: true });
        const { t } = this.props;
        let levelManager = (window as any).levelManager as LevelManager;
        if (levelManager.downloadNecessary) {
            Swal.fire({
                text: t('Dialogs.LoadProjectConfirm'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('Dialogs.ButtonYes'),
                cancelButtonText: t('Dialogs.ButtonNo'),
                confirmButtonColor: '#FD5151',
                cancelButtonColor: '#00A2E8',
                heightAuto: false
            }).then((result) => {
                if (!result.value) {
                    this.setState({ open: false });
                }
            });
        }
    };

    /**
     * Hide this modal.
     */

    close = () => {
        this.setState({ open: false });
        this.props.parent.handleClose();
    };

    /**
     * Same as close, but does not close the parent.
     */
    closeWithoutParent = () => {
        this.setState({ open: false });
    };

    /**
     * Reads a local file and loads it as a level.
     * @param {ReactFilesFile[]} files
     */
    readLevelFileAndLoad = (files: ReactFilesFile[]) => {
        if (files.length > 0) {
            let r = new FileReader();
            let fileName = files[0].name.slice(0, -5);
            r.readAsText(files[0] as Blob);
            r.onload = () => {
                try {
                    let levelManager = (window as any).levelManager as LevelManager;
                    levelManager.importFile(r.result as string, { type: LoadingTypes.LOCAL_JSON }, undefined, undefined, fileName);
                    window.history.pushState({}, document.title, '?lang=' + i18n.language);
                } catch (e) {
                    Logging.error(e.toString());
                }
                this.close();
            };
        }
    };


    /**
     * Loads and opens a level from URL.
     * @param {string} url
     */
    loadURLLevel(url: string) {
        try {
            AppRoot.INSTANCE.pauseProject();
            let levelManager = (window as any).levelManager as LevelManager;
            levelManager.importFile(url, {
                type: LoadingTypes.URL_JSON
            });
            window.history.pushState({}, document.title, '?lang=' + i18n.language + '&level=' + window.btoa(url));
        } catch (e) {
            Logging.error(e.toString());
        }
        this.close();
    }

    async loadURLLevelCode(code: string) {
        try {
            AppRoot.INSTANCE.pauseProject();
            await loadLevelAws(code)
            window.history.pushState({}, document.title, '?lang=' + i18n.language + '&levelCode=' + code);
        } catch (e) {
            Logging.error(e.toString());
        }
        this.close();
    }

    loadCustomURLLevel = () => {
        this.loadURLLevel(this.state.customURL);
    };

    handleCustomURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ customURL: event.target.value });
    };

    loadLevelsConfig() {
        const t = this;
        const levelsConfig = SessionStorage.get('levelsConfig');
        if (levelsConfig) {
            t.setState({ levelsConfig: levelsConfig });
            return;
        }
    }

    handleAccessKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ levelAccessKey: event.target.value });
    };

    handleAccessKeyKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleLoadLevelFromCloud();
        }
    }

    handleLoadLevelFromCloud = async () => {
        const {levelAccessKey} = this.state;
        this.setState({ showProgress: true });
        const success = await loadLevelAws(levelAccessKey);
        window.history.pushState({}, document.title, '?lang=' + i18n.language + '&levelCode=' + levelAccessKey);
        this.setState({ showProgress: false });
        if (success) this.close();
    }

    render() {
        const { t, trigger } = this.props;
        const levelsConfig = this.state.levelsConfig;

        return ([
            React.cloneElement(trigger, { key: "1", onClick: () => this.open() }),
            (
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                    maxWidth={"lg"}
                    fullWidth
                >
                    <DialogTitle id="level-dialog-slide-title">
                        {t('LevelLoadModal.title')}
                    </DialogTitle>
                    <LinearProgress hidden={!this.state.showProgress} style={{ marginBottom: "10px" }} />
                    <DialogContent style={{ overflow: "hidden" }}>
                        <p>{t('LevelLoadModal.ChooseLevelInfo')}</p>
                        <Grid container={true} spacing={6}>
                            <Grid item={true} md={7}>
                                <LevelLoadModalTabs levelConfig={levelsConfig} parent={this}></LevelLoadModalTabs>
                            </Grid>
                            <Grid item={true} md={5}>
                                <Box style={{ paddingBottom: "50px" }} component="div" display="block">
                                    <form>
                                        <TextField
                                            required
                                            fullWidth={true}
                                            value={this.state.levelAccessKey}
                                            onChange={this.handleAccessKeyChange}
                                            onKeyPress={this.handleAccessKeyKeyPress}
                                            label={t('LevelLoadModal.LevelLoadAWSPlaceholder')}
                                            variant="outlined"
                                        />
                                        <IT4KidsButtonWithStyles variant="contained" style={{ marginTop: "10px" }} onClick={() => {
                                            this.handleLoadLevelFromCloud();
                                        }}>
                                            {t('LevelLoadModal.LevelLoadAWS')}
                                        </IT4KidsButtonWithStyles>
                                    </form>

                                </Box>

                                <div style={{ border: "dashed", borderColor: "gray" }}>
                                    <Files
                                        style={{ height: "200px" }}
                                        accepts={['.cubi']}
                                        onChange={this.readLevelFileAndLoad}
                                        maxFileSize={30000000} // 30mb
                                        minFileSize={0}
                                        multiple={false}
                                        clickable={true}
                                    >
                                        <div
                                            style={{
                                                padding: "20px",
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                            }}
                                        >{t('LevelLoadModal.DragDropMessage')}
                                        </div>
                                    </Files>
                                </div>
                                {
                                    AppRoot.INSTANCE.view === "debug" &&
                                    <Box style={{ paddingTop: "50px" }} component="div" display="block">
                                        <TextField
                                            fullWidth={true}
                                            value={this.state.customURL}
                                            onChange={this.handleCustomURLChange}
                                            label="URL"
                                            variant="outlined"
                                        />
                                        <IT4KidsButtonWithStyles onClick={this.loadCustomURLLevel}>
                                            {t('SpriteEditor.URLCostumeModalTitle')}
                                        </IT4KidsButtonWithStyles>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <IT4KidsButtonWithStyles onClick={this.closeWithoutParent}>
                            {t('Cancel')}
                        </IT4KidsButtonWithStyles>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(LevelLoadModal);