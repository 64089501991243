import { Logging } from "./logging";
import { SessionStorage } from "./storage";
import LevelManager, { LoadingTypes, SaveType } from "../levels";
import WorkspaceManager from "../workspace";
import RenderUtils from '../render_utils';
import { endLoading } from '../containers/LoadingOverlay';

interface SessionSavePoint {
    time: number,
    levelData: object
}

interface SessionSaveManager {
    levels: Map<string, SessionSavePoint>,
    version: string
}

/**
 * Automatically caches the current state of the last levels of the session.
 * The ID used to save the level is the queryCode in the cloud.
 * This means this is not fully compatible with loading levels via URL and won't cache these properlly.
 */
export abstract class SessionSave {
    private static initialized = false;
    public static blockedReset = false;
    public static blockedCreate = false;
    private static identifier = 'LocalLevelCache';
    private static version = '1.0.2';

    public static initialize() {
        if (!this.initialized) {
            this.initialized = true;
            if (SessionStorage.get(this.identifier) === undefined) {
                this.generateStorage();
            } else {
                if (SessionStorage.get(this.identifier).version !== this.version) {
                    this.generateStorage();
                } else {
                    this.blockedReset = true;
                }
            }
        }
    }

    public static reset() {
        if (!this.blockedReset) {
            let sessionSaveManager = SessionStorage.get(this.identifier) as SessionSaveManager;
            sessionSaveManager.levels = new Map();
            SessionStorage.set(this.identifier, sessionSaveManager);
        } else {
            this.blockedReset = false;
        }
    }

    public static createLevelSessionSave() {
        if (!this.blockedCreate) {
            let levelQueryCode = LevelManager.levelCodeOrUrl;
            if (levelQueryCode) { // Only save level to session if there is a unique level code
                let sessionSaveManager = SessionStorage.get(this.identifier) as SessionSaveManager;
                let levelManager = (window as any).levelManager as LevelManager;
                let level = levelManager.save(SaveType.OBJECT);
                let newLevelCacheSave = {
                    time: this.getTimeStamp(),
                    levelData: level
                };
                sessionSaveManager.levels[levelQueryCode] = newLevelCacheSave;
                SessionStorage.set(this.identifier, sessionSaveManager);
            }
        }
    }

    public static canLoadLocalLevelSave(levelCodeOrUrl: string) {
        let restoreManager = SessionStorage.get(this.identifier) as SessionSaveManager;
        return !!restoreManager.levels[levelCodeOrUrl];
    }

    public static loadLocalLevelSave(levelCodeOrUrl: string) {

        let restoreManager = SessionStorage.get(this.identifier) as SessionSaveManager;
        Logging.info('Loading local session storage cache');
        let levelManager = (window as any).levelManager as LevelManager;
        let workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        workspaceManager.stopAll();
        const loadInfo = {
            type: LoadingTypes.LOCAL_JSON,
            buildMode: LevelManager.isBuildMode(),
            levelCodeOrUrl: levelCodeOrUrl
        };
        levelManager.load(restoreManager.levels[levelCodeOrUrl].levelData, loadInfo, undefined, () => {
            //Despite using the level finsihed callback, we need to wait some more time.
            //This is a BUG. I assume that Blockly (or maybe even our code) is doing some background work async so that
            //no blocks are present when the callback is called.
            //Using setTimeout(..., 0) sets the this.runProject() to the current bottom of the javascript stack, so that we are queued after all other async functions.)
            setTimeout(() => {
                ((window as any).renderUtils as RenderUtils).generateColorPalette();
                (window as any).app_onResizeSplitPanel();
                endLoading();
            }, 0);
        });
        SessionStorage.set(this.identifier, restoreManager);
    }

    private static generateStorage() {
        SessionStorage.set(this.identifier, {
            levels: {},
            version: this.version
        });
    }

    private static getTimeStamp() {
        return Date.now();
    }
}