import FlatWorld from "./world";
import WorkspaceManager from "./workspace";
import { validate as checkJSONSchema, ValidatorResult } from "jsonschema";
import { Logging } from "./utils/logging";
import Auxiliary from "./utils/auxiliary";
import { Blockly } from "./blocks";
import Base64 from './utils/b64';
import Hashing from './utils/hashing';
// (bug in version of ESLint bundled with our version of react-scripts)
// eslint-disable-next-line
import { endLoading, withLoading } from "./containers/LoadingOverlay";
// Auto generated with typescript-json-schema. See package.json scripts.
import levelSchema from "./levelSchema.json";
import { LevelSavedState, SavedState, TutorialSavedState, WinState, WinStates } from "./level-state";
import { Restore } from "./utils/restore";
import { AppRoot } from "./app";
import { SpriteEditorInstance } from "./containers/SpriteEditor/SpriteEditor";
import { translate } from "./i18n/i18n";
import { sa_event } from "./utils/analytics";
import { fixeditorflag } from './containers/Menu';
import { SessionSave } from './utils/sessionSave';

/**
 * Holds all loading types to be used in {@link LevelManager.load}
 * <ul style="list-style: none;">
 *      <li>{@link LoadingTypes.URL_JSON}: Loads a JSON from remote.
 *      <li>{@link LoadingTypes.LOCAL_JSON}: Loads a dict/json from a locally stored file. (Not as string)
 *      <li>{@link LoadingTypes.CLOUD_JSON}: Loads a dict/json from a file stored in the cloud. (Not as string)
 * </ul>
 */
export enum LoadingTypes {
    URL_JSON,
    LOCAL_JSON,
    CLOUD_JSON
}

export interface LoadingInfo {
    type: LoadingTypes;
    buildMode?: boolean;
    levelCodeOrUrl?: string; // Unique level code or URL to identify the level in the session storage.
}

/**
 * All save types. Prefer encoded for smaller file sizes.
 * <ul style="list-style: none;">
 *      <li>{@link SaveType.LOCAL_JSON}: Saves to a JSON string.
 * </ul>
 */
export enum SaveType {
    LOCAL_JSON,
    CLOUD_JSON,
    OBJECT
}

interface UpgradeInfo {
    oldVersion: string;
    newVersion: string;
    upgradeFunc: (savedState: LevelSavedState) => boolean;
}

/**
 * A levelManager loads and saves state.
 *
 * @param workspaceManager
 * @param world
 * @class
 */
export default class LevelManager {

    private static INSTANCE: LevelManager|null;

    public downloadNecessary = false;

    // Functions for upgrading the level file
    private static upgradeInfo: UpgradeInfo[] = [
        {
            //Add properties for draggable and programmable sprites
            oldVersion: "none",
            newVersion: "0.4.1",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata = {version: "0.4.1"};

                savedState.projectdata.background.draggable = false;
                savedState.projectdata.background.programmable = false;

                savedState.projectdata.workspaces.forEach((ws) => {
                    ws.sprite.draggable = false;
                    ws.sprite.programmable = true;
                });
                return true;
            }
        },
        {
            //added variables
            oldVersion: "0.4.1",
            newVersion: "0.5.1",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata.version = "0.5.1";

                if (!savedState.projectdata.variables) {
                    savedState.projectdata.variables = [];
                }
                const allWorkspaces = savedState.projectdata.workspaces.map((ws) => ws.workspace);
                allWorkspaces.push(savedState.projectdata.backgroundWorkspace);

                for (const workspaceState of allWorkspaces) {
                    if (!workspaceState.variables) {
                        workspaceState.variables = [];
                    }
                    if (workspaceState.variables.length > 0) {
                        let varIds = {};
                        const xml = Blockly.Xml.textToDom(Base64.decode(workspaceState.workspacexml)) as HTMLElement;
                        const childCount = xml.childNodes.length;
                        for (let i = 0; i < childCount; i++) {
                            const xmlChild = xml.childNodes[i];
                            if (xmlChild.nodeName.toLocaleLowerCase() === 'variables') {
                                const varCount = xmlChild.childNodes.length;
                                for (let j = 0; j < varCount; j++) {
                                    const varChild = xmlChild.childNodes[j];
                                    if (varChild.nodeType === Node.ELEMENT_NODE && varChild.nodeName.toLocaleLowerCase() === 'variable' && varChild.textContent) {
                                        varIds[varChild.textContent] = (varChild as Element).getAttribute('id');
                                    }
                                }
                                break;
                            }
                        }

                        for (let v of workspaceState.variables) {
                            v.id = varIds[v.name];
                            if (!v.id) {
                                return false;
                            }
                        }
                    }
                }
                return true;
            }
        },
        {
            //added monitors ans project setting for origin
            oldVersion: "0.5.1",
            newVersion: "0.6.0",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata.version = "0.6.0";

                if (!savedState.projectdata.monitors) {
                    savedState.projectdata.monitors = [];
                }

                if (!savedState.projectdata.settings) {
                    savedState.projectdata.settings = {
                        centerAsOrigin: true,
                    } as any;
                }

                return true;
            }
        },
        {
            //added level-title
            oldVersion: "0.6.0",
            newVersion: "0.6.1",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata.version = "0.6.1";

                if (!savedState.projectdata.title) {
                    savedState.projectdata.title = 'Level';
                }

                return true;
            }
        },
        {
            //added properties for initially loaded workspace and flag for systemCostume
            oldVersion: "0.6.1",
            newVersion: "0.7.0",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata.version = "0.7.0";
                savedState.projectdata.settings.selectedWorkspaceID = 0;

                savedState.projectdata.background.costumes.forEach((costume) => {
                    costume.isSystemCostume = false;
                });
                savedState.projectdata.workspaces.forEach((ws) => {
                    ws.sprite.costumes.forEach((costume) => {
                        costume.isSystemCostume = false;
                    });
                });
                return true;
            }
        },
        {
            //added properties for mirrored sprites
            oldVersion: "0.7.0",
            newVersion: "0.7.1",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata.version = "0.7.1";
                savedState.projectdata.background.orientation = 0;
                savedState.projectdata.background.mirrored = false;

                savedState.projectdata.workspaces.forEach((ws) => {
                    ws.sprite.orientation = 0;
                    ws.sprite.mirrored = false;
                });
                return true;
            }
        },
        {
            //decoy upgrade for implementing checksum
            oldVersion: "0.7.1",
            newVersion: "1.0.0",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata.version = "1.0.0";
                return true;
            }
        },
        {
            //added start configuration for resetting sprites
            oldVersion: "1.0.0",
            newVersion: "1.0.1",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata.version = "1.0.1";

                savedState.projectdata.workspaces.forEach((ws) => {
                    ws.sprite.startConfiguration = {
                        position: {
                            x: ws.sprite.position.x,
                            y: ws.sprite.position.y,
                        },
                        rotation: ws.sprite.rotation,
                        scale: ws.sprite.scale,
                        mirrored: ws.sprite.mirrored,
                        orientation: ws.sprite.orientation,
                        visible: ws.sprite.visible,
                        layer: ws.sprite.layer
                    };
                });

                return true;
            }
        },
        {
            //added properties for virtual Input
            oldVersion: "1.0.1",
            newVersion: "1.3.0",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata.version = "1.3.0";

                savedState.projectdata.workspaces.forEach((ws) => {
                    ws.sprite.mobileOnly = false;
                    ws.sprite.isInput = false;
                    ws.sprite.layer = 1;
                    ws.sprite.startConfiguration.layer = -1;
                });

                return true;
            }
        },
        {
            //added tutorial
            oldVersion: "1.3.0",
            newVersion: "2.7.2",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata.version = "2.7.2";
                savedState.projectdata.tutorial = undefined;
                savedState.projectdata.workspaces.forEach((ws) => {
                    ws.sprite.allowCostumeEdit = true;
                });
                return true;
            }
        },
        {
            //added sprite property deletable
            //added option to disable creating new sprites
            oldVersion: "2.7.2",
            newVersion: "2.7.3",
            upgradeFunc: (savedState: LevelSavedState) => {
                savedState.softwaredata.version = "2.7.3";
                savedState.projectdata.workspaces.forEach((ws) => {
                    ws.sprite.deletable = false;
                });
                savedState.projectdata.settings.allowNewSprite = true;
                return true;
            }
        }
    ];

    private static versionHistory = [
        "none",
        "0.4.1",
        "0.5.1",
        "0.6.0",
        "0.6.1",
        "0.7.0",
        "0.7.1",
        "1.0.0",
        "1.0.1",
        "1.3.0",
        "2.7.2",
        "2.7.3",
    ];

    workspaceManager: WorkspaceManager;
    world: FlatWorld;
    /**
     * Called when level is emptied.
     */
    onEmpty?: () => void;

    private _loadingInfo: LoadingInfo|null;

    static isBuildMode(): boolean {
        if (LevelManager.INSTANCE && LevelManager.INSTANCE._loadingInfo) {
            return !!LevelManager.INSTANCE._loadingInfo.buildMode;
        }
        return false;
    }

    public static levelTitleUpdate: boolean = true;
    public static levelTitle: string;
    public static levelURL: string;
    public static levelCodeOrUrl: string;
    public static tutorial: TutorialSavedState = {
        currentPageIndex: 0,
        pages: [],
        tutorialActive: false,
    };
    public static winState = {state: WinStates.NONE} as WinState;
    public static allowNewSprite: boolean = true;

    static toggleUserMode() {
        if (LevelManager.INSTANCE) {
            SpriteEditorInstance.INSTANCE.updateDragging(true);
            const instance = LevelManager.INSTANCE;
            const world = (window as any).world as FlatWorld;
            let buildMode = false;
            if (instance._loadingInfo) {
                buildMode = !instance._loadingInfo.buildMode;
                instance._loadingInfo.buildMode = buildMode;
                instance.workspaceManager.workspaces.forEach((ws) => {
                    //@ts-ignore
                    ws.blocklyWorkspace.options.buildMode = buildMode;
                    //@ts-ignore
                    ws.blocklyWorkspace.getTopComments(false).forEach((comment) => comment.setEditable(true));
                });
            }
            instance.workspaceManager.workspaces.forEach(ws => {
                //@ts-ignore
                let blocks = ws.blocklyWorkspace.getAllBlocks();
                for (let i = 0; i < blocks.length; i++) {
                    blocks[i].applyColour();
                    blocks[i].applyHide();
                }
            });
            if (!buildMode) {
                if (!instance.workspaceManager.getSprite(instance.workspaceManager.activeWorkspace!).programmable) {
                    for (let i = 0; i < world.sprites.length; i++) {
                        if (world.sprites[i].programmable) {
                            instance.workspaceManager.setActive(instance.workspaceManager.workspaces[i]);
                            break;
                        }
                    }
                }
            }
        }
    }

    /**
     * Upgrades level file to newest version
     *
     * @param {Object.<string, object>} savedState A possible level file.
     */
    private static upgrade(savedState: LevelSavedState) {
        let fileVersion = "none";
        // Check if level file has a version
        if (Object(savedState).hasOwnProperty("softwaredata")) {
            fileVersion = savedState.softwaredata.version;
        }
        // Applies all necessary upgrade-functions in order
        let currVersionIndex = LevelManager.versionHistory.indexOf(fileVersion);
        while (currVersionIndex < LevelManager.versionHistory.length - 1) {
            const oldVersion = LevelManager.versionHistory[currVersionIndex];
            const newVersion = LevelManager.versionHistory[currVersionIndex + 1];
            const upgrade = LevelManager.upgradeInfo.find((info) => info.oldVersion === oldVersion && info.newVersion === newVersion);
            // Call upgrade-function
            if (!upgrade || !upgrade.upgradeFunc(savedState)) {
                return false;
            }
            currVersionIndex += 1;
        }
        return true;
    }

    constructor(workspaceManager: WorkspaceManager, world: FlatWorld) {
        LevelManager.INSTANCE = this;
        this.workspaceManager = workspaceManager;
        this.world = world;
        this._loadingInfo = null;
        window.addEventListener('beforeunload', this.beforeunload.bind(this));
    }

    /**
     * Imports file by downloading, verifying and finally loading
     * @param data The level info, depending on the loadingType.
     * @param loadingInfo which method should be used for loading?
     * @param toolbox
     * @param cb Callback called when loading finished.
     * @param fileName if known
     */
    @withLoading({autoEnd: false})
    importFile(data: string, loadingInfo: LoadingInfo, toolbox?: string[], cb?: () => void, fileName?: string) {
        Restore.blockedCreate = true;
        (window as any).analytics.reportToSimpleAnalytics();
        switch (loadingInfo.type) {
            case LoadingTypes.URL_JSON:
                const levelURL = data;
                if (levelURL === '¿') {
                    break;
                }
                if (!Auxiliary.isValidUrl(levelURL)) {
                    Logging.error(translate('Messages.InvalidUrl'), true);
                    break;
                }
                fileName = levelURL.replace(/.*\//, '').slice(0, -5);
                fetch(levelURL)
                    .then((response) => response.text())
                    .then((respText) => {
                        let level = this.verifyFile(respText);
                        if (level !== null) {
                            if (Number(fixeditorflag) === 0) {
                                sa_event('open_level_url', {newLevelTitle: level.projectdata.title, newLevelURL: new URL(levelURL).href.split('?')[0]});
                            }
                            if (loadingInfo.buildMode === undefined) {
                                loadingInfo.buildMode = this._loadingInfo?.buildMode;
                            }
                            if (this.load(level, loadingInfo, toolbox, cb, fileName, levelURL)) {
                                window.setTimeout(() => this.downloadNecessary = false, 500);
                                Restore.blockedCreate = false;
                                Restore.reset(level);
                            }
                        }
                    })
                    .catch((err) => {
                        AppRoot.INSTANCE.newLevel();
                        Logging.error("Failed to load level: " + err);
                    })
                    .then(() => (endLoading()));
                break;
            case LoadingTypes.LOCAL_JSON:
                let level = this.verifyFile(data);
                if (level !== null) {
                    if (loadingInfo.buildMode === undefined) {
                        loadingInfo.buildMode = this._loadingInfo?.buildMode;
                    }
                    if (this.load(level, loadingInfo, toolbox, cb, fileName)) {
                        if (Number(fixeditorflag) === 0) {
                            sa_event('open_level_file', {newLevelName: level.projectdata.title});
                        }
                        window.setTimeout(() => this.downloadNecessary = false, 500);
                        Restore.blockedCreate = false;
                        Restore.reset(level);
                    }
                }
                endLoading();
                break;
            case LoadingTypes.CLOUD_JSON:
                let levelCloud = this.verifyFile(data);
                if (levelCloud !== null) {
                    if (loadingInfo.buildMode === undefined) {
                        loadingInfo.buildMode = this._loadingInfo?.buildMode;
                    }
                    if (this.load(levelCloud, loadingInfo, toolbox, cb, fileName)) {
                        if (Number(fixeditorflag) === 0) {
                            sa_event('open_level_cloud', {newLevelName: levelCloud.projectdata.title});
                        }
                        window.setTimeout(() => this.downloadNecessary = false, 500);
                        Restore.blockedCreate = false;
                        Restore.reset(levelCloud);
                    }
                }
                endLoading();
            
        }
    }

    /**
     * Checks if file is a valid JSON-format
     * Verifies the checksum if one exists
     *
     * @param file
     */
    verifyFile(file: string): LevelSavedState|null {
        let level = null;
        try {
            level = JSON.parse(file);
        } catch (e) {
            Logging.error('Die Level-Datei ist nicht im Cubi-Format', true);
            Logging.error(e);
            AppRoot.INSTANCE.newLevel();
            return null;
        }

        let firstKey = Object.keys(level)[0];
        if (firstKey.substr(0, 6) === 'CHKSUM') {
            let hash = firstKey.substr(8, 16);
            level = level[firstKey];
            if (hash !== Hashing.generateHash(JSON.stringify(level))) {
                sa_event('invalid_level_hash');
                Logging.error('Die Level-Datei ist beschädigt', true);
                if (AppRoot.INSTANCE.view === 'debug') {
                    return level;
                }
                AppRoot.INSTANCE.newLevel();
                return null;
            }
        }

        return level;
    }

    /**
     * Saves the current level and returns the save object.
     * @see LevelManager.load
     * @param {SaveType} type See {@link SaveType} for explanation.
     */
    save(type?: SaveType): any {
        Logging.info(`Saving level.`);
        let levelSave = {
            projectdata: this.workspaceManager.serialize(),
            softwaredata: LevelManager.getSerializedSoftwareData()
        };

        this.downloadNecessary = true;

        switch (type) {
            case SaveType.LOCAL_JSON:
                return JSON.stringify(levelSave);
            case SaveType.CLOUD_JSON:
                return JSON.stringify(levelSave);
            case SaveType.OBJECT:
                return levelSave;
            default:
                return "";
        }
    }

    /**
     * Saves the level and returns exported file with a checksum
     */
    exportFile(type?: SaveType): string {
        if (Number(fixeditorflag) === 0) {
            switch (type) {
                case SaveType.LOCAL_JSON:
                    sa_event('save_level_local');
                    break;
                case SaveType.CLOUD_JSON:
                    sa_event('save_level_cloud');
                    break;
                default:
                    sa_event('save_level');
            }

        }
        let level = this.save(SaveType.OBJECT);
        let data = JSON.stringify(level);
        Restore.setLastDownloadedLevel(level);
        let hash = Hashing.generateHash(data);
        this.downloadNecessary = false;
        return '{"CHKSUM>#' + hash + '#":' + data + '}';
    }

    /**
     * Reset the workspace/all sprites to start configuration.
     */
    reset() {
        Logging.info(`Resetting sprites to start configuration.`);
        const world = (window as any).world as FlatWorld;
        world.sprites.forEach((sprite) => {
            if (!sprite.isStaticBackground) {
                sprite.loadStartConfiguration();
            }
        });
    }

    /**
     * Resets the whole level manager.
     */
    empty() {
        if (this.onEmpty) {
            this.onEmpty();
        }
        (window as any).analytics.resetAll();
        Logging.debug("Removing all existing level data.");
        // Delete all current workspaces
        this.workspaceManager.clear();

        // Delete existing sprites
        this.world.removeAllSprites();
        this.world.removeAllMonitors();
        this.world.updateOrigin(true);

        this._loadingInfo = {
            type: LoadingTypes.LOCAL_JSON,
            buildMode: false
        };
    }

    /**
     * Loads a level synchronously!
     *
     * @param {Object.<string, object>} savedState A possible level file.
     * @param loadingInfo The type in which the object is loaded.
     * @param {Object} toolbox An optional debug toolbox.
     * @param {Function} cb Callback called when loading finished.
     *
     * @param fileName
     * @param url If given, stores the URL that was used to load the level for analytics.
     * @returns {boolean} Whether the level loaded successfully.
     */
    @withLoading()
    load(savedState: LevelSavedState, loadingInfo: LoadingInfo, toolbox?: string[], cb?: () => void, fileName?: string, url?: string): boolean {
        Logging.info("Loading level.");
        this.workspaceManager.codeRunner.stop();

        // Override toolbox
        if (toolbox) {
            savedState.projectdata.workspaces.forEach(item => (
                    item.workspace.toolbox = toolbox
                )
            );
        }
        // Upgrade level file to newest version
        if (!LevelManager.upgrade(savedState)) {
            Logging.error("Die Level-Datei konnte nicht geupgraded werden", true);
            endLoading();
            return false;
        }

        if (fileName) {
            if (savedState.projectdata.title === 'Level') {
                savedState.projectdata.title = fileName;
            }
        }

        if (url) {
            LevelManager.levelURL = url;
        }

        if (loadingInfo.levelCodeOrUrl) {
            LevelManager.levelCodeOrUrl = loadingInfo.levelCodeOrUrl || url || '';
        }

        LevelManager.levelTitle = savedState.projectdata.title;
        LevelManager.levelTitleUpdate = true;
        LevelManager.tutorial = savedState.projectdata.tutorial || LevelManager.tutorial;

        // Validate level file
        let validation = LevelManager.validate(savedState);
        if (validation.errors.length > 0) {
            Logging.error("Die Level-Datei hat ein falsches Format", true);
            for (let error of validation.errors) {
                Logging.error(error.toString());
            }
            if (process.env.NODE_ENV === "development") {
                console.warn(
                    "If you changed the levelstructure, please restart the server."
                );
            }
            endLoading();
            return false;
        }

        // Clean all existing level info
        this.empty();

        this._loadingInfo = loadingInfo;

        // Load from saved state
        this.workspaceManager.deserialize(savedState.projectdata, loadingInfo);

        // If given, call level finished callback
        if (cb) {
            cb();
        }

        this.workspaceManager.codeRunner.unpause();
        AppRoot.INSTANCE.state.isPlaying = false;

        return true;
    }

    /**
     * dialog for unsaved changes
     */
    beforeunload(e: any) {
        AppRoot.INSTANCE.resetProject();
        SessionSave.createLevelSessionSave();
        Restore.createRestorePoint(translate("RestoreMessages.automatic"));
        Restore.shrinkRestorePoints();
    }

    /*
     * Serializes the SoftwareSavedState
     */
    private static getSerializedSoftwareData() {
        return {version: LevelManager.versionHistory[LevelManager.versionHistory.length - 1]};
    }

    /**
     * Checks a level file/JSON against the spec defined in levelSchema.json.
     * This file is generated on each build.
     *
     * @param {LevelSavedState} savedState A stored file.
     * @return {ValidatorResult}
     */
    private static validate(savedState: LevelSavedState): ValidatorResult {
        return checkJSONSchema(savedState, levelSchema);
    }

}

/**
 * Every component of Cubi that can be saved to the project file should extend this class.
 * Also check {@link SavedState} and see existing components.
 *
 * This class should be an interface, but since we need a static deserialize
 * and TS doesn't allow that (yet?), we resort to using this approach to
 * minimize developer error. https://github.com/Microsoft/TypeScript/issues/14600
 */
export class Serializeable {

    static deserialize(savedState: SavedState, loadingInfo: LoadingInfo): void {
        throw new Error("Needs implementation in sub class.");
    }

    serialize(): SavedState {
        throw new Error("Needs implementation in sub class.");
    }
}
